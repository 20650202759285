import React, { useState, useEffect } from 'react';
import SearchPageModel from './Models/SearchPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { InitSearchResult } from './Search';
import { styled, theme } from '../Theme';
import CardGrid from '../Shared/Grid/CardGrid';
import ProductCard from '../ProductCard/ProductCard';
import Dots from '../Shared/Button/Dots';
import { media, pseudo } from '@glitz/core';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { getUrlParameter } from '../Shared/Common/Helpers';
import {
  EventDispatcher,
  ON_SEARCH,
  ON_MOBILE_SEARCH_OPEN_CHANGE,
  ON_SEARCH_MODAL_OPEN_CHANGE,
} from '../Shared/Common/EventDispatcher';
import ProductCardModel from '../ProductCard/Models/ProductCardModel.interface';
import KexLink from '../../Features/Shared/KexLink/KexLink';
import { mediaQuery } from '../Theme/mediaQueries';
import FullSearchResult from '../Search/Models/FullSearchResult.interface';
import { useKexLoadingCircle } from '../Shared/Loading/KexLoadingCircle/KexLoadingCircle';
import { useUserStateData } from '../Shared/UserContextProvider/UserContextProvider';
import LoadMoreContainer from '../Shared/LoadMoreContainer/LoadMoreContainer';

function SearchPage() {
  const {
    translations: {
      'search/pages': pagesLabel,
      'searchPage/provideQuery': provideQueryLabel,
      'searchPage/searchResultsFor': searchResultsForLabel,
      'common/filter': filterLabel,
      'common/sector': sectorLabel,
      'search/noResults': noResultsLabel,
      'searchPage/products': products,
      'search/planogram': planogramLabel,
      'search/loadMore': loadMoreLabel,
      'search/category': categoryLabel,
      'searchPage/counter': counter,
      'common/showMore': showMoreLabel,
    },
    staticPages: { searchPage },
    languageRoute,
    requestToken,
  } = useAppSettingsData();

  const { organizationId } = useUserStateData();
  const dispatchLoading = useKexLoadingCircle();
  const { pageTitle, channelId, pageId, itemsPerLoad } = useCurrentPage<
    SearchPageModel
  >();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchModalIsOpen, setSearchModalIsOpen] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [visibleProducts, setVisibleProducts] = useState<ProductCardModel[]>();
  const [visiblePlanograms, setVisiblePlanograms] = useState<
    ProductCardModel[]
  >();
  const [nrOfVisibleProducts, setNrOfVisibleProducts] = useState(itemsPerLoad);
  const [nrOfVisiblePlanograms, setNrOfVisiblePlanograms] = useState(
    itemsPerLoad
  );

  const showLoadMoreProducts =
    searchResult &&
    searchResult?.productSearchResult?.numberOfItems > itemsPerLoad;
  const showLoadMorePlanograms =
    searchResult &&
    searchResult?.planogramSearchResult?.numberOfItems > itemsPerLoad;

  const onSearch = (value: boolean) => {
    setIsSearching(value);
  };

  const onSearchModalChange = (isOpen: boolean) => {
    setSearchModalIsOpen(isOpen);
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_SEARCH, onSearch);
    EventDispatcher.subscribe(
      ON_MOBILE_SEARCH_OPEN_CHANGE,
      onSearchModalChange
    );
    EventDispatcher.subscribe(ON_SEARCH_MODAL_OPEN_CHANGE, onSearchModalChange);
    return () => {
      EventDispatcher.unsubscribe(ON_SEARCH, onSearch);
      EventDispatcher.unsubscribe(
        ON_SEARCH_MODAL_OPEN_CHANGE,
        onSearchModalChange
      );
      EventDispatcher.unsubscribe(
        ON_MOBILE_SEARCH_OPEN_CHANGE,
        onSearchModalChange
      );
    };
  });

  var query = getUrlParameter('searchQuery');

  useEffect(() => {
    dispatchLoading('add');
    InitSearchResult(
      query,
      requestToken,
      organizationId,
      pageId,
      channelId,
      languageRoute
    ).then(data => {
      setSearchResult(data);
      if (
        data?.productSearchResult &&
        data.productSearchResult?.numberOfItems > 0
      ) {
        let sliced = data.productSearchResult?.items.slice(0, itemsPerLoad);
        setVisibleProducts(sliced);
      } else {
        setVisibleProducts(undefined);
      }
      if (
        data?.planogramSearchResult &&
        data.planogramSearchResult?.numberOfItems > 0
      ) {
        let sliced = data.planogramSearchResult?.items.slice(0, itemsPerLoad);
        setVisiblePlanograms(sliced);
      } else {
        setVisiblePlanograms(undefined);
      }
    });
    dispatchLoading('remove');
  }, [query]);

  const noSearchResult =
    !searchResult?.contentSearchResult &&
    !searchResult?.productSearchResult &&
    ((searchResult?.categoryMatch && !!!searchResult?.categoryMatch.length) ||
      !searchResult?.categoryMatch) &&
    !searchResult?.planogramSearchResult;

  const loadMoreProducts = () => {
    if (searchResult) {
      setIsLoadingMore(true);

      let sliced = searchResult.productSearchResult?.items.slice(
        nrOfVisibleProducts,
        nrOfVisibleProducts + itemsPerLoad
      );
      let itemsToShow = visibleProducts?.concat(sliced);

      setVisibleProducts(itemsToShow);
      setIsLoadingMore(false);
      nrOfVisibleProducts &&
        setNrOfVisibleProducts(nrOfVisibleProducts + itemsPerLoad);
    }
  };

  const loadMorePlanograms = () => {
    if (searchResult) {
      setIsLoadingMore(true);

      let sliced = searchResult.planogramSearchResult?.items.slice(
        nrOfVisiblePlanograms,
        nrOfVisiblePlanograms + itemsPerLoad
      );
      let itemsToShow = visiblePlanograms?.concat(sliced);

      setVisiblePlanograms(itemsToShow);
      setIsLoadingMore(false);
      nrOfVisiblePlanograms &&
        setNrOfVisiblePlanograms(nrOfVisiblePlanograms + itemsPerLoad);
    }
  };

  return (
    <Main>
      <TopSection>
        <>
          {isSearching && !searchModalIsOpen ? (
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          ) : !noSearchResult ? (
            <QueryHeader>
              <SearchResultSpan>
                {query ? searchResultsForLabel : provideQueryLabel}
              </SearchResultSpan>
              {query && <SearchQuery>{query}</SearchQuery>}
            </QueryHeader>
          ) : (
            <QueryHeader>
              <SearchResultSpan>
                {query ? noResultsLabel : provideQueryLabel}
              </SearchResultSpan>
            </QueryHeader>
          )}
        </>
      </TopSection>
      <Divider />
      <BottomSection>
        <StyledCardGrid>
          <SearchResultContainer>
            {searchResult?.contentSearchResult?.items && (
              <PageResultContainer>
                <PageResultHeading>{`${
                  searchResult?.contentSearchResult?.items.length
                }${' '}${pagesLabel}`}</PageResultHeading>
                <PageResultItemsContainer>
                  {searchResult?.contentSearchResult?.items.map(
                    item =>
                      item.src && (
                        <PageResultItem
                          key={item.name + item.src}
                          href={item.src}
                        >
                          <PageResultItemText>{item.name}</PageResultItemText>
                        </PageResultItem>
                      )
                  )}
                </PageResultItemsContainer>
              </PageResultContainer>
            )}
            {searchResult?.categoryMatch &&
              !!searchResult?.categoryMatch.length && (
                <PageResultContainer>
                  <PageResultHeading>{`${
                    searchResult?.categoryMatch?.length
                  }${' '}${categoryLabel}`}</PageResultHeading>
                  <PageResultItemsContainer>
                    {searchResult?.categoryMatch?.map(
                      item =>
                        item.url && (
                          <PageResultItem
                            key={item.url + item.name}
                            href={item.url}
                          >
                            <PageResultItemText>{item.name}</PageResultItemText>
                          </PageResultItem>
                        )
                    )}
                  </PageResultItemsContainer>
                </PageResultContainer>
              )}
            {searchResult &&
              searchResult?.planogramSearchResult?.numberOfItems > 0 && (
                <PageResultContainer>
                  <PageResultHeading>{`${
                    searchResult?.planogramSearchResult?.items.length
                  }${' '}${planogramLabel}`}</PageResultHeading>
                  <ProductCardGrid>
                    {visiblePlanograms &&
                      !!visiblePlanograms.length &&
                      visiblePlanograms.map(
                        item =>
                          item.url && (
                            <ProductCard
                              key={item.name + item.url}
                              item={item}
                              isPlanogram={true}
                            />
                          )
                      )}
                  </ProductCardGrid>
                  {showLoadMorePlanograms && visiblePlanograms && (
                    <LoadMoreContainer
                      numberOfItems={visiblePlanograms?.length}
                      onChange={() => loadMorePlanograms()}
                      availableItems={
                        searchResult?.planogramSearchResult?.numberOfItems
                      }
                      disabled={
                        searchResult &&
                        nrOfVisiblePlanograms >=
                          searchResult?.planogramSearchResult?.numberOfItems
                      }
                      isPlanograms={true}
                    />
                  )}
                </PageResultContainer>
              )}
            {searchResult &&
              searchResult?.productSearchResult?.numberOfItems > 0 && (
                <PageResultContainer>
                  <PageResultHeading>{`${
                    searchResult?.productSearchResult?.items.length
                  }${' '}${products}`}</PageResultHeading>
                  <ProductCardGrid>
                    {visibleProducts &&
                      !!visibleProducts.length &&
                      visibleProducts.map(
                        item =>
                          item.url && (
                            <ProductCard
                              key={item.name + item.url}
                              item={item}
                            />
                          )
                      )}
                  </ProductCardGrid>
                  {showLoadMoreProducts && visibleProducts && (
                    <LoadMoreContainer
                      numberOfItems={visibleProducts?.length}
                      onChange={() => loadMoreProducts()}
                      availableItems={
                        searchResult?.productSearchResult?.numberOfItems
                      }
                      disabled={
                        searchResult &&
                        nrOfVisibleProducts >=
                          searchResult?.productSearchResult?.numberOfItems
                      }
                    />
                  )}
                </PageResultContainer>
              )}
          </SearchResultContainer>
        </StyledCardGrid>
      </BottomSection>
    </Main>
  );
}

export default SearchPage;

const Main = styled.main({
  backgroundColor: theme.primaryBackground,
});

const StyledCardGrid = styled(CardGrid, { maxWidth: theme.screenMaxWidth });

const ProductCardGrid = styled(CardGrid, {
  paddingTop: '22px',
  maxWidth: theme.screenMaxWidth,
  paddingBottom: '42px',
});

const SearchResultSpan = styled.span({
  fontSize: theme.delta,
  lineHeight: '22px',
  marginBottom: theme.spacing(2),
});

const SearchQuery = styled.q({
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  color: theme.black,
  lineHeight: '32px',
});

const TopSection = styled.div({
  paddingTop: theme.spacing(11),
  paddingBottom: theme.spacing(13),
});

const BottomSection = styled.div({
  margin: { x: theme.spacing(4), bottom: theme.spacing(8) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    display: 'flex',
    padding: { x: theme.spacing(2) },
  }),
});

const SearchResultContainer = styled.div({
  gridColumnEnd: 'span 4',
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridColumnEnd: 'span 6',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumnStart: '2',
    gridColumnEnd: 'span 12',
    display: 'flex',
    flexDirection: 'column',
    padding: { x: theme.spacing(2) },
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridColumnStart: '3',
  }),
  ...media(theme.mediaQuery.mediaMinScreen, {
    gridColumnStart: '4',
  }),
});

const DotsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(9),
});

const Divider = styled.div({
  borderBottom: { width: '1px', style: 'solid', color: theme.grayLine },
  marginBottom: theme.spacing(10),
  ...pseudo([':nth-child(n)[data-thicker="true"]'], {
    marginTop: theme.spacing(6),
    border: {
      xy: { width: '1px', style: 'solid', color: theme.grayLine },
    },
  }),
  ...media(mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(19),
  }),
});

const QueryHeader = styled.h1({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const PageResultContainer = styled.div({});

const PageResultItemsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: { y: theme.spacing(5.5) },
});

const PageResultHeading = styled.span({ fontSize: '22px', color: theme.black });

const PageResultItem = styled(KexLink, {
  display: 'flex',
  borderRadius: '50px',
  backgroundColor: theme.lightBlue,
  color: theme.primaryBlue,
  padding: { y: theme.spacing(4), x: theme.spacing(2) },
  marginRight: theme.spacing(5),
  marginBottom: theme.spacing(5),
  ':active': { backgroundColor: theme.primaryBlue, color: theme.white },
  textTransform: 'uppercase',
  ...media(mediaQuery.mediaMinLarge, {
    ':hover': { backgroundColor: theme.lightBlueHover },
    ':active': { backgroundColor: theme.primaryBlue, color: theme.white },
  }),
});

const PageResultItemText = styled.div({
  padding: { x: theme.spacing(4) },
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  lineHeight: '14px',
});
